import { injectSlice } from '@common/configs/redux/InjectSlice';
import type { AnySliceLike } from '@common/configs/redux/UtilTypes';
import {
  ajaxBaseQuery,
  type AjaxBaseQueryFn,
  type AjaxBaseQueryOptions
} from '@common/libs/ajax/AjaxBaseQuery';
import { createSlice } from '@reduxjs/toolkit';
import {
  createApi,
  type BaseQueryFn,
  type CreateApiOptions,
  type EndpointDefinitions
} from '@reduxjs/toolkit/query/react';
import { isFunction } from 'lodash';

export const createLazyInjectedApi = <
  Definitions extends EndpointDefinitions,
  BaseQuery extends BaseQueryFn = AjaxBaseQueryFn,
  ReducerPath extends string = 'api',
  TagTypes extends string = never,
>(options: Override<CreateApiOptions<BaseQuery, Definitions, ReducerPath, TagTypes>, {
    baseQuery?: BaseQuery | AjaxBaseQueryOptions
  }>
  ) => {
  const {
    baseQuery: baseQueryFn,
    ...restOptions
  } = options;

  const baseQuery = (isFunction(baseQueryFn) ? baseQueryFn : ajaxBaseQuery(baseQueryFn)) as BaseQuery;

  const apiService = createApi({
    baseQuery,
    ...restOptions
  });

  injectSlice(apiService as unknown as AnySliceLike);

  return apiService;
};

export const createLazyInjectedSlice: typeof createSlice = (options) => {
  const slice = createSlice(options);
  injectSlice(slice);
  return slice;
};
