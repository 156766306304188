import ResetStoreAction from '@common/configs/redux/reset-store.action';
import type {
  AnySliceLike,
  Enhancers,
  ExistingSliceLike,
  InitialState,
  SliceLike,
  SlicesArray
} from '@common/configs/redux/UtilTypes';
import {
  combineSlices,
  configureStore,
  createDynamicMiddleware,
  Tuple,
  type Action,
  type ConfigureStoreOptions,
  type Middleware,
  type StoreEnhancer,
  type UnknownAction
} from '@reduxjs/toolkit';
import type {
  CombinedSliceReducer,
  InjectConfig,
  WithSlice
} from '@reduxjs/toolkit/dist/combineSlices';
import type { Middlewares } from '@reduxjs/toolkit/dist/configureStore';
import type { ThunkMiddlewareFor } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import type {
  ExtractDispatchExtensions,
  Id
} from '@reduxjs/toolkit/dist/tsHelpers';
import { setupListeners } from '@reduxjs/toolkit/query';
import { type createApi } from '@reduxjs/toolkit/query/react';
import {
  forEach,
  isFunction
} from 'lodash';

type ConfigureAppStoreOptions<
  S = unknown,
  A extends Action = UnknownAction,
  M extends Tuple<Middlewares<S>> = Tuple<[ThunkMiddlewareFor<S>]>,
  E extends Tuple<Enhancers> = Tuple<[
    StoreEnhancer<{
        dispatch: ExtractDispatchExtensions<M>;
    }>,
    StoreEnhancer
  ]>,
  P = S
> = Omit<
  ConfigureStoreOptions<S, A, M, E, P>,
  'reducer' | 'preloadedState'
>;

export function createAppStoreConfigurator<
  InitialSlices extends SlicesArray = [],
  LazySlices extends SlicesArray = []
>(...slices: InitialSlices) {
  type Slices = [...InitialSlices, ...LazySlices];
  type RootState = InitialState<Slices>;

  const internalSlices = (slices ?? []) as unknown as Slices;

  const combinedReducer = combineSlices<Slices>(...internalSlices);

  const injectedSlices = new Set<string>();

  forEach(internalSlices, (slice) => {
    if (isSliceLikeObjectInstance(slice)) {
      injectedSlices.add(slice.reducerPath);
    } else if (typeof slice === 'object') {
      Object.keys(slice).forEach((key) => {
        return injectedSlices.add(key);
      });
    }
  });

  return function configureAppStore<
    S extends RootState = RootState,
    A extends Action = UnknownAction,
    M extends Tuple<Middlewares<S>> = Tuple<[ThunkMiddlewareFor<S>]>,
    E extends Tuple<Enhancers> = Tuple<[
        StoreEnhancer<{
          dispatch: ExtractDispatchExtensions<M>;
        }>,
        StoreEnhancer
      ]>,
    P = S
  >(storeOptions: ConfigureAppStoreOptions<S, A, M, E, P> = {}) {
    const {
      middleware,
      enhancers,
      devTools
    } = storeOptions;

    const rootReducer = Object.assign((state: RootState, action: UnknownAction) => {
      const st = ResetStoreAction.match(action) ? undefined : state;
      return combinedReducer(st, action);
    }, combinedReducer);

    const dynamicMiddleware = createDynamicMiddleware();

    const store = configureStore({
      devTools: devTools ?? process.env.NODE_ENV === 'development',
      reducer: rootReducer,
      middleware: (getDefaultMiddleware) => {
        const defaultMiddleware = isFunction(middleware)
          ? middleware(getDefaultMiddleware)
          : getDefaultMiddleware({ serializableCheck: false });

        defaultMiddleware.forEach((m: Middleware) => {
          dynamicMiddleware.addMiddleware(m);
        });

        return new Tuple(dynamicMiddleware.middleware);
      },
      enhancers
    });

    // Reset the store to ensure that the middleware are all applied
    store.dispatch(ResetStoreAction());

    setupListeners(store.dispatch);

    function injectSlice<Sl extends Id<ExistingSliceLike<S>>>(
      slice: Sl,
      config?: InjectConfig
    ): CombinedSliceReducer<S, Id<S & WithSlice<Sl>>>;

    function injectSlice<ReducerPath extends string, State>(
      slice: SliceLike<ReducerPath, State & (ReducerPath extends keyof S ? never : State)>,
      config?: InjectConfig
    ): CombinedSliceReducer<S, Id<S & WithSlice<SliceLike<ReducerPath, State>>>>;

    function injectSlice<
      SliceLikeObject extends AnySliceLike,
      ReducedState = SliceLikeObject extends AnySliceLike
        ? CombinedSliceReducer<S, Id<S & WithSlice<SliceLikeObject>>>
        : SliceLikeObject extends SliceLike<infer ReducerPath, infer State>
        ? CombinedSliceReducer<S, Id<S & WithSlice<SliceLike<ReducerPath, State>>>>
        : never
    >(
      sliceLikeObject: SliceLikeObject,
      config?: InjectConfig
    ): ReducedState {
      if (!injectedSlices.has(sliceLikeObject.reducerPath) || config?.overrideExisting) {
        const out = combinedReducer.inject(sliceLikeObject, config);

        store.replaceReducer(rootReducer);

        if (isRTKQApiInstance(sliceLikeObject)) {
          dynamicMiddleware.addMiddleware(sliceLikeObject.middleware);
        }

        injectedSlices.add(sliceLikeObject.reducerPath);

        return out as ReducedState;
      }

      return combinedReducer as unknown as ReducedState;
    }

    return {
      store,
      injectSlice
    };
  };
}

export function isRTKQApiInstance(obj: unknown): obj is ReturnType<typeof createApi> {
  return obj != null && typeof obj === 'object' && 'endpoints' in obj && 'middleware' in obj;
}

export function isSliceLikeObjectInstance(obj: unknown): obj is AnySliceLike {
  return obj != null && typeof obj === 'object' && 'reducerPath' in obj && 'reducer' in obj;
}
