/**
 * AjaxBaseQuery.ts
 * This is a replacement for the default fetchBaseQuery function provided by RTK Query. It allows us to avoid using
 * fetch() and instead use our own $.ajax() layer, but still get what we want out of RTK Query.
 *
 * The options passed to AjaxBaseQuery are much the same as you're used to passing to our $.ajax() calls today.
 * Example:
 * {
 *  // apiEndpoint will handle prefixing the endpoint path with /axonify/{userType}/
 *  // apiEndpoint will also override the url option
 *  apiEndpoint: '/games', (api endpoint path as a string)
 *  // url will be overridden by apiEndpoint if provided
 *  // url needs to be the full endpoint path including /axonify/{userType}/ fragment
 *  // url can also be used to specify the full url endpoint path including the domain
 *  url: '/axonify/admin/games', (api endpoint path as a string)
 *  method: 'GET', (the HTTP method to use as a string)
 *  data: {} (the payload or query params as an object),
 * }
 */

import { type ApiErrorResponse } from '@common/services/error/AxonifyException';
import { type BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { isObject } from 'lodash';

export type AjaxBaseQueryParams = JQuery.AjaxSettings & {
  apiEndpoint?: string
  url?: string
  method?: string
  data?: string | JQuery.PlainObject
  options?: Record<string, unknown>
  shouldShowErrorPage?: boolean;
  // This sender information is to adapt the new RTKQ requests to use Admin's global error/success handler toast messaging
  sender?: {
    className?: string;
    toJSON?: () => Record<string, unknown>;
  }
};

export interface AjaxBaseQueryOptions {
  skipGlobalHandler?: JQuery.SkipGlobalHandler;
  shouldShowErrorPage?: boolean;
  senderName?: string;
}

export type AjaxBaseQueryFn = BaseQueryFn<AjaxBaseQueryParams, unknown, ApiErrorResponse>;

export type AjaxBaseQueryErrorData<ResponseErrorData extends object = EmptyObject> = {
  error: {
    xhr: JQuery.jqXHR
    textStatus: string
    errorThrown: string
    data: ResponseErrorData
  }
};

const getSuccessData = (data: Record<string, unknown>) => {
  return {
    data
  };
};

const getErrorData = <ResponseErrorData extends object = EmptyObject>(jqXHR: JQuery.jqXHR, textStatus: string, errorThrown: string): AjaxBaseQueryErrorData<ResponseErrorData> => {
  return {
    error: {
      xhr: jqXHR,
      textStatus,
      errorThrown,
      data: jqXHR.responseJSON ?? {}
    }
  };
};

export function isErrorData<ErrorResponse extends object = EmptyObject>(obj: unknown): obj is AjaxBaseQueryErrorData<ErrorResponse>['error'] {
  return isObject(obj) && 'xhr' in obj && 'textStatus' in obj && 'errorThrown' in obj && 'data' in obj;
}

export const ajaxBaseQuery = (baseQueryOptions?: AjaxBaseQueryOptions): AjaxBaseQueryFn => {
  const {
    skipGlobalHandler,
    shouldShowErrorPage,
    senderName
  } = baseQueryOptions ?? {skipGlobalHandler: false};

  return async ({
    data, // data on a GET is the query params, and the payload on all other request methods
    ...options
  }, {signal}) => {

    const ajaxOptions = {
      skipGlobalHandler,
      shouldShowErrorPage,
      showSpinner: false,
      data,
      sender: senderName && {
        className: senderName,
        toJSON: () => {
          return data;
        }
      },
      beforeSend: (xhr: JQueryXHR) => {
        // Attach the AbortController's signal to the XMLHttpRequest
        if (signal) {
          signal.addEventListener('abort', () => {
            xhr.abort(); // Abort the request when the signal is triggered
          }, {once: true});
        }
      },
      ...options
    };

    // This probably looks like it can be cleaned up. Maybe it looks like it doesn't have to be wrapped in a
    // Promise.resolve(). Unfortunately, RTK Query is picky and I've been down this rabbit hole before. Forgive the mess
    // and don't touch it ;)
    return Promise.resolve(
      $.ajax(ajaxOptions)
        .then(getSuccessData, getErrorData)
    )
      .catch((errorObj) => {
        return errorObj;
      });
  };
};

export type AjaxBaseQuery = typeof ajaxBaseQuery;
